import { Injectable } from '@angular/core';
import {
  NodeModel,
  PageColumn,
  PageModel,
  PageType,
} from '@ay-gosu/server-shared';
import { safeJsonParse } from '@ay/util';
import { from } from 'rxjs';
import { filter, map, mergeMap, shareReplay } from 'rxjs/operators';
import { TokenService } from './token.service';

export interface PageColumnExt extends PageColumn {
  open?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PageService {
  public list$ = this.tokenService.rxAccount$.pipe(
    filter((account) => !!account),
    mergeMap(() => from(this.loadList())),
    shareReplay(1),
  );

  public allowPaths$ = this.list$
    .pipe(
      map((pages) => {
        let res: string[] = [];
        pages.forEach((page) => {
          res = res.concat(this.allowPathHandler(page));
        });
        return res;
      }),
    )
    .pipe(shareReplay(1));

  public constructor(private tokenService: TokenService) {}

  private allowPathHandler(page: PageColumnExt): string[] {
    let res: string[] = [];
    switch (page.type) {
      case 'Page':
        res.push(page.path);
        break;

      case 'Group':
        if (!page.children) break;
        page.children.forEach((p) => {
          res = res.concat(this.allowPathHandler(p));
        });
        break;
    }
    return res;
  }

  private async loadList(): Promise<PageColumnExt[]> {
    const dynamicForms = await this.loadGenerateFormList();
    let pages = await PageModel.list();
    pages = pages.sort((a, b) => a.order - b.order);

    const forms = dynamicForms.map((form) => ({
      path: `/form/${form.id}`,
      label: form.name,
      type: 'Page' as PageType,
      icon: 'play_arrow',
    }));

    if (dynamicForms.length >= 3) {
      pages.splice(1, 0, {
        label: $localize`執行流程 （${dynamicForms.length}）`,
        type: 'Group' as PageType,
        icon: 'play_arrow',
        children: forms,
      });
    } else {
      pages.splice(1, 0, ...forms);
    }

    pages.forEach((page, idx) => {
      if (page.type !== 'Group') return;
      if (page.children && page.children.length >= 1) {
        page.children = page.children.sort((a, b) => a.order - b.order);
      }
    });

    return pages.map((page: PageColumnExt) => {
      if (page.type == 'Group' && typeof page.open != 'boolean') {
        page.open = false;
      }
      return page;
    });
  }

  private async loadGenerateFormList() {
    let list = await NodeModel.getGenerateFormList();

    return list
      .map((form) => {
        form.data = safeJsonParse(form.data, form.data);
        return form;
      })
      .filter(
        (form) =>
          form.data &&
          form.data['formConfig'] instanceof Array &&
          form.data['formConfig'].length > 0,
      );
  }

  public hasPermissionForPath$(path: string) {
    return this.list$.pipe(
      map((pages) => pages.find((page) => page.path === path) !== undefined),
      shareReplay(1),
    );
  }
}
